import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { getConfig } from "./config";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(function (envelope) {
  envelope.tags["ai.cloud.role"] = getConfig("ai_role_name");
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  appInsights.config.disableTelemetry = true;
}

export { reactPlugin, appInsights };
