import { fetchFunctionHelper } from "../fetcher";
import { formatter } from "../formatter";

export function fetcherResourceTypes() {
  const endpoint = formatter("/core/resourcetypes");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherResourceDefs() {
  const endpoint = formatter("/core/resourcedefinitions");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherResourceDef(resourceDefId) {
  const endpoint = formatter(`/core/resourcedefinitions/${resourceDefId}`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherResourceDefSave(resource) {
  const endpoint = formatter(`/core/resourcedefinitions/${resource.id}`);
  const method = "PUT";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    resource: resource,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherResourceDefCreate(resource) {
  const endpoint = formatter(`/core/resourcedefinitions`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = resource;

  return fetchFunctionHelper(endpoint, method, headers, body);
}
