import createConfig from "react-runtime-config";

export const { useConfig, useAdminConfig, getConfig } = createConfig({
  namespace: "MY_APP_CONFIG",
  schema: {
    backend: {
      type: "string",
      description: "Backend Url", // config without `default` need to be provided into `window.MY_APP_CONFIG`
      // default: "https://clearview-api.az.cie.ac/beta"
      possibleValues: [
        { key: "PRD", value: "https://clearview-api.az.cie.ac/v1" },
        { key: "NPRD", value: "https://clearview-api.az.cie.ac/beta" },
        { key: "Review", value: "https://clearview-api.az.cie.ac/review" },
        { key: "Local", value: "http://localhost:7071/api" },
      ],
    },
    ai_role_name: {
      type: "string",
      description: "Application Insights Role name", // config without `default` need to be provided into `window.MY_APP_CONFIG`
      default: "ClearView-NPRD",
    },
  },
});
