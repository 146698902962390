import React from "react";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";

import { RecoilRoot } from "recoil";

import { msalConfig } from "./helpers/authProvider";

import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";

import "react-toastify/dist/ReactToastify.css";

import "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css";

import "./scss/style.scss";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { TheLayout } from "./containers";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "./helpers/AppInsights";

const pca = new PublicClientApplication(msalConfig);

// Pages
import { routes } from "./routes";
const browserRoutes = routes.map((route) => {
  return {
    path: route.path,
    element: route.component,
  };
});

const router = createBrowserRouter([
  { path: "/", element: <TheLayout />, children: browserRoutes },
  { path: "*", element: <Navigate to="/customer" /> },
]);

function App() {
  return (
    <AppInsightsErrorBoundary
      onError={() => (
        <center>
          <h1>Something went wrong</h1>
          <h2>Trace ID: {appInsights.context.telemetryTrace.traceID}</h2>
        </center>
      )}
      appInsights={reactPlugin}
    >
      <RecoilRoot>
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <RouterProvider router={router} />
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </RecoilRoot>
    </AppInsightsErrorBoundary>
  );
}

export default withAITracking(reactPlugin, App);
