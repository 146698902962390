import { useMsal } from "@azure/msal-react";

export function useAuth() {
  const { instance, accounts } = useMsal();

  //New MSAL code
  async function getAccessToken() {
    if (accounts.length > 0) {
      const request = {
        scopes: ["api://40f4851e-0b8d-4041-b9e2-23e7495e7073/.default"],
        account: accounts[0],
      };
      try {
        return (await instance.acquireTokenSilent(request)).accessToken;
      } catch (error) {
        return (await instance.acquireTokenRedirect(request)).accessToken;
      }
    }
  }

  // async function getAccessTokenKV(){
  //   if (accounts.length > 0) {
  //     const request = {
  //         scopes: ["https://vault.azure.net/.default"],
  //         account: accounts[0]
  //     };
  //     try {
  //       return (await instance.acquireTokenSilent(request)).accessToken
  //     }
  //     catch (error) {
  //       return (await instance.acquireTokenRedirect(request)).accessToken
  //     }
  //   }
  // }

  // async function getAccessTokenAzureManagement(){
  //   if (accounts.length > 0) {
  //     const request = {
  //         scopes: ["https://management.azure.com/user_impersonation"],
  //         account: accounts[0]
  //     };
  //     try {
  //       return (await instance.acquireTokenSilent(request)).accessToken
  //     }
  //     catch (error) {
  //       return (await instance.acquireTokenRedirect(request)).accessToken
  //     }
  //   }
  // }

  async function getAccessTokenMSGraph() {
    if (accounts.length > 0) {
      const request = {
        scopes: ["https://graph.microsoft.com/.default"],
        account: accounts[0],
      };
      try {
        return (await instance.acquireTokenSilent(request)).accessToken;
      } catch (error) {
        return (await instance.acquireTokenRedirect(request)).accessToken;
      }
    }
  }

  function logout() {
    if (accounts.length > 0) {
      const request = {
        account: accounts[0],
      };
      instance.logoutRedirect(request);
    }
  }

  return { getAccessToken, getAccessTokenMSGraph, accounts, logout };
}
