import { fetchFunctionHelper } from "../fetcher";
import { formatter } from "../formatter";

export function testEndpoint(endpoint) {
  return fetchFunctionHelper(endpoint, "GET", undefined, undefined, undefined, 1000);
}

export function fetcherGlobalSyncLocksmith() {
  const endpoint = formatter("/Locksmith-Sync");
  const method = "POST";
  return fetchFunctionHelper(endpoint, method);
}
