import { fetchFunctionHelper, getSecureCosmosObject } from "../fetcher";
import { formatter } from "../formatter";

export function fetcherAADGroupMembership(token, user = "me") {
  const endpoint = "https://graph.microsoft.com/v1.0/" + user + "/getMemberGroups";
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const body = { securityEnabledOnly: true };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherAADSync() {
  return [];
}

export function fetcherAzureAssets(selectedCustomerId) {
  const endpoint = formatter("/Tag-GetSubscriptionAndResources");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: selectedCustomerId,
    filterList: true,
    cosmosDb: getSecureCosmosObject("helpers"),
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherAzureAssetsWithDetails(azureAccessToken, selectedCustomer) {
  const endpoint = formatter("/Subscriptions-GetTenantSubscriptionListWithDetails");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: selectedCustomer.id,
    azureAccessToken: azureAccessToken,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherAzureADTenantUserList(selectedCustomer) {
  const endpoint = formatter("/AAD-TenantUserGroupList");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: selectedCustomer.id,
    keyvaultUrl: process.env.REACT_APP_AZURE_AD_CSP_IUR_LANDINGZONE_KEYVAULT,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherAzureADUserActivation(
  selectedCustomer,
  user,
  infrauser,
  infrapass,
  optionMail,
  optionSms,
  primaryDomain
) {
  const endpoint = formatter("/AAD-UserActivation");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    keyvaultUrl: process.env.REACT_APP_AZURE_AD_CSP_IUR_LANDINGZONE_KEYVAULT,
    keyvaultInitialPasswordUrl: process.env.REACT_APP_AZURE_AD_CSP_IUR_INITIALPASSWORD_KEYVAULT,
    tenantId: selectedCustomer.id,
    tenantName: primaryDomain,
    user: user,
    options: {
      optionMail: optionMail,
      optionSms: optionSms,
    },
    smsApi: {
      username: infrauser,
      password: infrapass,
    },
    sendGridApi: {
      customData: {},
    },
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherAzureADTenantInfo(selectedCustomer) {
  const endpoint = formatter("/AAD-TenantInfo");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    keyvaultUrl: process.env.REACT_APP_AZURE_AD_CSP_IUR_LANDINGZONE_KEYVAULT,
    tenantId: selectedCustomer.id,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}
