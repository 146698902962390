// eslint-disable-next-line no-sparse-arrays

export default [
  {
    _tag: "CSidebarNavItem",
    name: "Global Dashboard",
    to: "/dashboard",
    icon: "cil-layers",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Resource Definitions",
    to: "/resourcedefinitions",
    icon: "cil-settings",
  },
];

export const navigationFiltered = [
  {
    _tag: "CSidebarNavItem",
    name: "Overview",
    to: "/customer/:customerId/overview",
    icon: "cil-magnifying-glass",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_OVERVIEW,
    permission: "customers:write",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Classic Pipelines",
    to: "/customer/:customerId/pipeline",
    icon: "cib-azure-devops",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_PIPELINES,
    permission: "pipelines:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Modern Pipelines",
    to: "/customer/:customerId/modernpipeline",
    icon: "cib-azure-devops",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_PIPELINES,
    permission: "pipelines:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Azure AD",
    to: "/customer/:customerId/azuread",
    icon: "cil-layers",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "tagging:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Component Quality Check",
    to: "/customer/:customerId/qc",
    icon: "cil-check",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_QUALITYCHECK,
    permission: "qualitycheck:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Documentation",
    to: "/customer/:customerId/docsgenerator",
    icon: "cil-file",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "documentation:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Integration Flow",
    to: "/customer/:customerId/if",
    icon: "cil-vector",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_QUALITYCHECK,
    permission: "qualitycheck:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Key Vault",
    to: "/customer/:customerId/keyvault",
    icon: "cil-lock-locked",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "keyvault:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Monitoring Config",
    to: "/customer/:customerId/monitoringconfiggenerator",
    icon: "cil-bell",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "documentation:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Monitoring Suppression",
    to: "/customer/:customerId/monitoringsuppression",
    icon: "cil-bell",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "documentation:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Mission Control",
    to: "/customer/:customerId/missioncontrol",
    icon: "cil-graph",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "documentation:read",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Modern Mission Control",
    to: "/customer/:customerId/modernmissioncontrol",
    icon: "cil-graph",
    mode: process.env.REACT_APP_MODULE_CUSTOMER_RESOURCE_TAGGING,
    permission: "documentation:read",
  },
];
