import React from "react";
import { CFooter } from "@coreui/react";

import { useRecoilValue } from "recoil";
import { softwareVersionState } from "../helpers/recoil";

const TheFooter = () => {
  const softwareVersion = useRecoilValue(softwareVersionState);

  return (
    <CFooter className="mt-auto">
      <div>
        <a href="https://accenture.com" target="_blank" rel="noopener noreferrer">
          Accenture
        </a>
        <span className="ml-1">&copy; 2020-2023 Azure Team Belgium. | Software Version: {softwareVersion}</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">Powered by </span>
        <a href="https://coreui.io/react" target="_blank" rel="noopener noreferrer">
          CoreUI for React
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
