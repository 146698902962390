import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import crypto from "crypto";

import { useRecoilValue } from "recoil";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CButton,
  CLink,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CWidgetStatsB,
  CPlaceholder,
  CCardText,
  CTooltip,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import _ from "lodash";

import { selectedCustomerState, multiOrgPATState } from "../../helpers/recoil";
import {
  fetcherDevopsGetItems,
  fetcherDevopsGetRepositories,
  fetcherPipelineHistoryLatest,
  fetcherRepositoryPipelineHistory,
  fetcherRepositoryPipelineHistoryForEnv,
  fetcherRepositoryPipelineHistoryForSolution,
} from "../../helpers/fetchers";
import DiffModal from "./parts/modernMissionControl/DiffModal";
import PipelineHistory from "./parts/modernMissionControl/PipelineHistory";
import { toast } from "react-toastify";
import semver from "semver";

const ModernMissionControl = () => {
  const selectedCustomer = useRecoilValue(selectedCustomerState);
  const personalAccessTokens = useRecoilValue(multiOrgPATState);
  const navigate = useNavigate();

  const [repositoryList, setRepositoryList] = useState(new Map());
  const [selectedRepository, setSelectedRepository] = useState();
  const [common, setCommon] = useState();
  const [globalParams, setGlobalParams] = useState();
  const [globalTags, setGlobalTags] = useState();
  const [historyVisible, setHistoryVisible] = useState(false);

  const [solutionDiff, setSolutionDiff] = useState(null);

  const [solutionData, setSolutionData] = useState(new Map());
  const [environmentData, setEnvironmentData] = useState(new Map());
  const [pipelineHistory, setPipelineHistory] = useState([]);

  const devOpsProjects = selectedCustomer.azure?.devopsRepositories.map((project) => {
    return { organization: "AzureLZ", name: project };
  });
  const externalDevopsProjects = selectedCustomer.azure?.externalDevopsProjects || "";

  const projectList = [...devOpsProjects, ...externalDevopsProjects];

  useEffect(() => {
    if (projectList) {
      setCommon();
      setSolutionData(new Map());
      setEnvironmentData(new Map());
      setPipelineHistory([]);

      let repoList = new Map();
      const getRepos = async () => {
        const dataArray = await Promise.all(
          projectList.map((project) => {
            const pat = personalAccessTokens[project.organization.toLowerCase()];
            if (pat) {
              return fetcherDevopsGetRepositories(project.organization, project.name, pat);
            } else {
              toast.error(`PAT not configured for ${project.organization}`);
            }
          })
        );

        for (const request of dataArray) {
          request?.result?.data?.value.forEach((v) => {
            if (v.isDisabled) {
              return;
            }

            const organization = v.url.match(/https:\/\/dev.azure.com\/(?<organization>\w*)\/*/).groups.organization;
            repoList.set(v.name, {
              organization: organization,
              projectId: v.project.id,
              projectName: v.project.name,
              projectDescription: v.project.description,
              repositoryId: v.id,
              name: v.name,
            });
          });
        }

        setRepositoryList(repoList);
      };
      getRepos();
    }
  }, [selectedCustomer]); // eslint-disable-line

  useEffect(() => {
    setSolutionData(new Map());
    setEnvironmentData(new Map());
    setPipelineHistory([]);

    const repo = repositoryList.get(selectedRepository);

    if (repo) {
      const fetchCommon = async () => {
        const rawCommon = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          // "?path=%2F_moved_to_LuminusITDigital/common.json&includeContent=true",
          "?path=%2Fcommon.json&includeContent=true",
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        const rawGlobalParams = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          "?path=%2Fglobal-parameters.json&includeContent=true",
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        const rawGlobalTags = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          "?path=%2Fglobal-tags.json&includeContent=true",
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        setCommon(JSON.parse(rawCommon.result.data.content));
        setGlobalParams(rawGlobalParams.result.data.content);
        setGlobalTags(rawGlobalTags.result.data.content);
      };

      fetchCommon();
    }
  }, [selectedRepository]);

  const getRepoHistory = useCallback(async () => {
    const repo = repositoryList.get(selectedRepository);
    const history = await fetcherRepositoryPipelineHistory(repo.projectId, repo.repositoryId);
    setPipelineHistory(history.result?.data);
  }, [selectedRepository, repositoryList]);

  const getRepoHistoryForEnv = useCallback(
    async (environment) => {
      const repo = repositoryList.get(selectedRepository);
      const history = await fetcherRepositoryPipelineHistoryForEnv(repo.projectId, repo.repositoryId, environment);
      setPipelineHistory(history.result?.data);
    },
    [selectedRepository, repositoryList]
  );

  const getRepoHistoryForSolution = useCallback(
    async (environment, solution) => {
      const repo = repositoryList.get(selectedRepository);
      const history = await fetcherRepositoryPipelineHistoryForSolution(
        repo.projectId,
        repo.repositoryId,
        environment,
        solution
      );
      setPipelineHistory(history.result?.data);
    },
    [selectedRepository, repositoryList]
  );

  function calculateStatus(currentSolutionData, latestSolutionData) {
    let errors = new Map();

    if (_.isNil(latestSolutionData) || _.isNil(currentSolutionData)) {
      return { statusOk: null, errors: errors };
    }

    if (latestSolutionData.status !== "Succeeded") {
      errors.set("deploymentStatus", "Latest deployment did not succeed");
    }
    if (latestSolutionData.deploymentMode === "Deploy Tags") {
      errors.set("deploymentMode", "Latest deployment was a 'Deploy Tags' pipeline, check the changes");
    }
    if (!latestSolutionData.matchingVersions) {
      errors.set("version", "Latest deployment did not satisfy the common version");
    }

    if (!latestSolutionData.matchingConfig) {
      errors.set("matchingConfig", "Latest deployment did not match the committed code");
    }

    if (errors.size === 0) {
      return { statusOk: true, errors: errors };
    }
    return { statusOk: false, errors: errors };
  }

  const getData = useCallback(
    async (environment, solution) => {
      if (!selectedRepository) {
        return;
      }

      const repo = repositoryList.get(selectedRepository);
      const history = await fetcherPipelineHistoryLatest(repo.projectId, repo.repositoryId, environment, solution);
      const latestSolutionConfig = await fetcherDevopsGetItems(
        repo.organization,
        repo.projectId,
        repo.repositoryId,
        //`?path=${encodeURI(`/${environment}/${solution}/config-template.json`)}&includeContent=true`,
        `?path=${encodeURI(`/${environment}/${solution}/config.bicepparam`)}&includeContent=true`,
        personalAccessTokens[repo.organization.toLowerCase()]
      );

      const getLatestGlobalParams = await fetcherDevopsGetItems(
        repo.organization,
        repo.projectId,
        repo.repositoryId,
        `?path=${encodeURI(`/global-parameters.json`)}&includeContent=true`,
        personalAccessTokens[repo.organization.toLowerCase()]
      );

      const getLatestGlobalTags = await fetcherDevopsGetItems(
        repo.organization,
        repo.projectId,
        repo.repositoryId,
        `?path=${encodeURI(`/global-tags.json`)}&includeContent=true`,
        personalAccessTokens[repo.organization.toLowerCase()]
      );

      const commonSolution = common.solutions[environment].find((obj) => {
        return obj.name === solution;
      });

      let mergeConfig = "";
      if (latestSolutionConfig?.result?.data?.content) {
        mergeConfig = latestSolutionConfig.result.data.content;
      }

      let currentHash = "";
      let lastDeployHash = "";

      const latestDeployment = history.result?.data;
      let newSolutionData = {};
      if (!_.isUndefined(latestDeployment)) {
        let splittedMergeConfig;
        let splittedLatestDeployment;
        try {
          // remove first line to compare code
          let lines = latestDeployment.configContent.split("\n");
          lines.splice(0, 1);
          splittedLatestDeployment = lines.join("\n");

          let linesMerge = mergeConfig.split("\n");
          linesMerge.splice(0, 1);
          splittedMergeConfig = linesMerge.join("\n");

          //compare code

          lastDeployHash = crypto.createHash("SHA256").update(splittedLatestDeployment).digest("hex");
          currentHash = crypto.createHash("SHA256").update(splittedMergeConfig).digest("hex");
        } catch (error) {
          toast.error(`Failed to process ${solution} in ${environment}`);
        }

        newSolutionData.status = history.result.data.status;
        newSolutionData.deploymentMode = history.result.data.deploymentMode;
        newSolutionData.matchingConfig = currentHash === lastDeployHash;
        newSolutionData.runtimeSolutionVersion = history.result.data.runtimeSolutionVersion;
        newSolutionData.matchingVersions = semver.satisfies(
          history.result.data.runtimeSolutionVersion,
          commonSolution.solutionBranch
        );
        newSolutionData.commitId = history.result.data.commitId;
        newSolutionData.mergedConfig = splittedMergeConfig;
        newSolutionData.deployedConfig = splittedLatestDeployment;
        newSolutionData.latestGlobalParams = getLatestGlobalParams.result?.data?.content;
        newSolutionData.currentGlobalParams = globalParams;
        newSolutionData.currentGlobalTags = globalTags;
        newSolutionData.latestGlobalTags = getLatestGlobalTags.result?.data?.content;
        newSolutionData.projectId = history.result.data.projectId;
        newSolutionData.organization = history.result.data.organization || "AzureLZ";
        newSolutionData.repositoryId = history.result.data.repositoryId;

        return newSolutionData;
      }

      return null;
    },
    [common, selectedRepository, globalParams, globalTags]
  );

  const getSolutionDataForEnv = useCallback(
    async (environment) => {
      let trackedSolutions = 0;
      let succeededSolutions = 0;
      let upToDateSolutions = 0;

      const varCache = new Map();
      await Promise.all(
        common.solutions[environment].map(async (solution) => {
          let solutionObj = solutionData.get(`${environment}/${solution.name}`);
          if (_.isUndefined(solutionObj)) {
            solutionObj = await getData(environment, solution.name, varCache);
            const tempMap = new Map(solutionData.set(`${environment}/${solution.name}`, solutionObj));
            setSolutionData(tempMap);
          }

          if (!_.isNull(solutionObj)) {
            if (solutionObj.status === "Succeeded") {
              succeededSolutions++;
            }
            if (solutionObj.matchingConfig && solutionObj.matchingVersions) {
              upToDateSolutions++;
            }
            trackedSolutions++;
          }

          return;
        })
      );

      const environmentInfo = {};
      environmentInfo.upToDate = Math.round((upToDateSolutions / common.solutions[environment].length) * 100);
      environmentInfo.tracked = Math.round((trackedSolutions / common.solutions[environment].length) * 100);
      environmentInfo.succeeded = Math.round((succeededSolutions / common.solutions[environment].length) * 100);

      setEnvironmentData(new Map(environmentData.set(environment, environmentInfo)));
    },
    [common, getData, solutionData, environmentData]
  );

  if (!selectedCustomer) {
    navigate("/customer");
  }

  return (
    <>
      <CRow className="mb-3">
        <CCol>
          <CCard>
            <CCardHeader className="contrast-title">Solution selector</CCardHeader>
            <CCardBody>
              <CRow className="form-group">
                <CCol md="4">
                  <CFormLabel>
                    <strong>Customer Repository</strong>
                  </CFormLabel>
                  <CFormSelect
                    name="csla"
                    id="csla"
                    value={selectedRepository}
                    onChange={(e) => setSelectedRepository(e.target.value)}
                  >
                    <option key="0" value="0">
                      Select Repository
                    </option>
                    {Array.from(repositoryList)
                      .sort((a, b) => a[1].name.localeCompare(b[1].name))
                      .map(([r, repo], k) => {
                        return (
                          <option key={k} value={r}>
                            {repo.name}
                          </option>
                        );
                      })}
                  </CFormSelect>
                </CCol>
              </CRow>

              <CRow>
                <CCol className="mt-2" md="12">
                  {selectedRepository && (
                    <CButton
                      variant="outline"
                      onClick={() => {
                        getRepoHistory();
                        setHistoryVisible(true);
                      }}
                    >
                      Pipeline history
                    </CButton>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <CCard>
            <CCardHeader className="contrast-title">Environment details</CCardHeader>
            <CCardBody>
              <CAccordion>
                {common &&
                  common.solutions &&
                  Object.keys(common.solutions).map((env, k) => {
                    const environmentInfo = environmentData.get(env);
                    return (
                      <CAccordionItem itemKey={`${k}-${env}`} key={`${k}-${env}`}>
                        <CAccordionHeader onClick={() => getSolutionDataForEnv(env)}>{env}</CAccordionHeader>
                        <CAccordionBody>
                          <CRow>
                            <CCol xs={4}>
                              <CWidgetStatsB
                                className="mb-3"
                                progress={{
                                  color: environmentInfo?.upToDate === 100 ? "success" : "warning",
                                  value: environmentInfo?.upToDate,
                                }}
                                text=""
                                title="Up-to-date deployments"
                                value={`${environmentInfo?.upToDate || 0}%`}
                              />
                            </CCol>

                            <CCol xs={4}>
                              <CWidgetStatsB
                                className="mb-3"
                                progress={{
                                  color: environmentInfo?.succeeded === 100 ? "success" : "warning",
                                  value: environmentInfo?.succeeded,
                                }}
                                text=""
                                title="Successful deployments"
                                value={`${environmentInfo?.succeeded || 0}%`}
                              />
                            </CCol>

                            <CCol xs={4}>
                              <CWidgetStatsB
                                className="mb-3"
                                progress={{
                                  color: environmentInfo?.succeeded === 100 ? "success" : "warning",
                                  value: environmentInfo?.tracked,
                                }}
                                text=""
                                title="Tracked solutions"
                                value={`${environmentInfo?.tracked || 0}%`}
                              />
                            </CCol>
                          </CRow>

                          <CRow className="mb-3">
                            <CCol>
                              {selectedRepository && (
                                <CButton
                                  variant="outline"
                                  onClick={() => {
                                    getRepoHistoryForEnv(env);
                                    setHistoryVisible(true);
                                  }}
                                >
                                  Get pipeline history
                                </CButton>
                              )}
                            </CCol>
                          </CRow>

                          <CRow>
                            {common.solutions[env].map((solution, i) => {
                              const solutionObject = solutionData.get(`${env}/${solution.name}`);
                              const finalStatus = calculateStatus(solution, solutionObject);
                              return (
                                <CCol xs={2} key={i}>
                                  <CCard
                                    className={`mb-3 border-${
                                      _.isNull(finalStatus.statusOk)
                                        ? "warning"
                                        : finalStatus.statusOk
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    <CCardHeader
                                      className={finalStatus.statusOk === false ? "bg-danger text-white" : ""}
                                    >
                                      {solution.name}
                                    </CCardHeader>
                                    <CCardBody>
                                      {_.isUndefined(solutionObject) ? (
                                        <CCardText>
                                          Last deployment status: <CPlaceholder xs={4} />
                                          <br />
                                          Config version (committed): <CPlaceholder xs={4} />
                                          <br />
                                          Deployed version: <CPlaceholder xs={4} />
                                          <br />
                                          Matching configs?: <CPlaceholder xs={4} />
                                          <br />
                                          CommitId: <CPlaceholder xs={4} />
                                          <br />
                                        </CCardText>
                                      ) : _.isNull(solutionObject) ? (
                                        <CCardText>
                                          Last deployment status: <span className="text-warning">Unknown</span>
                                          <br />
                                          Config version (committed): {solution?.solutionBranch}
                                          <br />
                                          Deployed version: <span className="text-warning">Unknown</span>
                                          <br />
                                          Matching configs?: <span className="text-warning">Unknown</span>
                                          <br />
                                          CommitId: <span className="text-warning">Unknown</span>
                                          <br />
                                        </CCardText>
                                      ) : (
                                        <CCardText>
                                          Last deployment status:{" "}
                                          {finalStatus.errors.get("deploymentStatus") ? (
                                            <CTooltip content={finalStatus.errors.get("deploymentStatus")}>
                                              <span className="text-danger">{solutionObject?.status}</span>
                                            </CTooltip>
                                          ) : finalStatus.errors.get("deploymentMode") ? (
                                            <CTooltip content={finalStatus.errors.get("deploymentMode")}>
                                              <span className="text-warning">{solutionObject?.status} (tags)</span>
                                            </CTooltip>
                                          ) : (
                                            <span className="text-success">{solutionObject?.status}</span>
                                          )}
                                          <br />
                                          Config version (committed): {solution?.solutionBranch}
                                          <br />
                                          Deployed version:{" "}
                                          {finalStatus.errors.get("version") ? (
                                            <CTooltip content={finalStatus.errors.get("version")}>
                                              <span className="text-danger">
                                                {solutionObject?.runtimeSolutionVersion}
                                              </span>
                                            </CTooltip>
                                          ) : (
                                            <span className="text-success">
                                              {solutionObject?.runtimeSolutionVersion}
                                            </span>
                                          )}
                                          <br />
                                          Matching configs?:{" "}
                                          {finalStatus.errors.get("matchingConfig") ? (
                                            <CTooltip content={finalStatus.errors.get("matchingConfig")}>
                                              <span className="text-danger">no</span>
                                            </CTooltip>
                                          ) : (
                                            <span className="text-success">yes</span>
                                          )}
                                          <br />
                                          CommitId:{" "}
                                          {solutionObject?.commitId && (
                                            <CLink
                                              target="_blank"
                                              className="text-decoration-underline"
                                              href={`https://dev.azure.com/${solutionObject.organization}/${solutionObject.projectId}/_git/${solutionObject.repositoryId}/commit/${solutionObject.commitId}`}
                                            >
                                              {solutionObject.commitId.substring(0, 7)}
                                            </CLink>
                                          )}
                                          <br />
                                        </CCardText>
                                      )}
                                      <CButton
                                        variant="outline"
                                        color="dark"
                                        onClick={() => getData(env, solution.name)}
                                      >
                                        <CIcon name={"cil-sync"} />
                                      </CButton>
                                      <CButton
                                        variant="outline"
                                        color="dark"
                                        className="ms-2"
                                        onClick={() => setSolutionDiff(`${env}/${solution.name}`)}
                                      >
                                        Compare
                                      </CButton>
                                      <CButton
                                        variant="outline"
                                        color="dark"
                                        className="ms-2"
                                        onClick={() => {
                                          getRepoHistoryForSolution(env, solution.name);
                                          setHistoryVisible(true);
                                        }}
                                      >
                                        Pipeline history
                                      </CButton>
                                    </CCardBody>
                                  </CCard>
                                </CCol>
                              );
                            })}
                          </CRow>
                        </CAccordionBody>
                      </CAccordionItem>
                    );
                  })}
              </CAccordion>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <PipelineHistory
        visible={historyVisible}
        onClose={() => setHistoryVisible(false)}
        pipelineData={pipelineHistory}
      />

      <DiffModal
        visible={!_.isNull(solutionDiff)}
        latestConfig={solutionData.get(solutionDiff)?.mergedConfig}
        currentConfig={solutionData.get(solutionDiff)?.deployedConfig}
        getLatestGlobalParams={solutionData.get(solutionDiff)?.latestGlobalParams}
        currentParamConfig={solutionData.get(solutionDiff)?.currentGlobalParams}
        currentGlobalTagsConfig={solutionData.get(solutionDiff)?.currentGlobalTags}
        getLatestGlobalTags={solutionData.get(solutionDiff)?.latestGlobalTags}
        onClose={() => setSolutionDiff(null)}
      />
    </>
  );
};

export default ModernMissionControl;
