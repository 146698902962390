import React from "react";
import { CSidebar, CSidebarBrand, CSidebarNav, CNavItem, CNavTitle } from "@coreui/react";

import CIcon from "@coreui/icons-react";

import { useRecoilValue } from "recoil";
import { generatePath, Link } from "react-router-dom";
import { selectedCustomerState, currentUserPermissionsState } from "../helpers/recoil";
import image from "../assets/icons/Acc_Logo_Black_Purple_RGB.png";

// sidebar nav config
import navigation, { navigationFiltered } from "./_nav";

const TheSidebar = () => {
  const currentUserPermissions = useRecoilValue(currentUserPermissionsState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  let updatedNavigationFiltered = navigationFiltered.filter(
    (v) => v.mode === "STABLE" && currentUserPermissions?.includes(v.permission)
    /*|| (v?.showalways /*|| (selectedCustomer?.country === "BE"))*/
  );

  return (
    <CSidebar className="c-sidebarlightgray sidebar-fixed">
      <CSidebarBrand className="c-sidebarlight sidebar-brand d-none d-md-flex" to="/">
        <img className="c-sideBarLogoWidth" src={image} alt="" width="200px" />
        <div className="c-line"></div>
      </CSidebarBrand>

      <CSidebarNav>
        <CNavTitle> Welcome</CNavTitle>
        {navigation.map((item) => {
          return (
            <CNavItem key={item.name}>
              <Link className="nav-link" to={item.to}>
                <CIcon className="me-3" name={item.icon} />
                {item.name}
              </Link>
            </CNavItem>
          );
        })}

        <CNavTitle> Customers</CNavTitle>
        <CNavItem>
          <Link className="nav-link" to="/customer">
            <CIcon className="me-3" name="cil-home" />
            Customers
          </Link>
        </CNavItem>

        {selectedCustomer && (
          <>
            <CNavTitle>{selectedCustomer?.companyProfile?.displayName}</CNavTitle>
            {updatedNavigationFiltered.map((item) => {
              return (
                <CNavItem key={item.name}>
                  <Link className="nav-link" to={generatePath(item.to, { customerId: selectedCustomer.id })}>
                    <CIcon className="me-3" name={item.icon} />
                    {item.name}
                  </Link>
                </CNavItem>
              );
            })}
          </>
        )}
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
