import React, { useEffect } from "react";
import { useNavigate, useLocation, useMatch, Link } from "react-router-dom";

import {
  CHeader,
  CHeaderNav,
  CNavItem,
  CNavLink,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
  CButton,
  CContainer,
  CHeaderDivider,
  // AppBreadcrumb
} from "@coreui/react";
// import CIcon from '@coreui/icons-react'

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  selectedCustomerState,
  clearviewCustomersState,
  azureResourceState,
  allAssignmentsState,
  tenantConfigState,
} from "../helpers/recoil";
import AppBreadcrumb from "./AppBreadcrumb";
import Select from "react-select";
import { useAuth } from "../helpers/authHooks";
import CIcon from "@coreui/icons-react";
import _ from "lodash";

const TheHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const customerMatch = useMatch({ path: "/customer/:customerId/*" });
  const query = new URLSearchParams(location.search);

  let assignments = useRecoilValue(allAssignmentsState);
  let [selectedCustomer, setSelectedCustomer] = useRecoilState(selectedCustomerState);
  let setAzureResources = useSetRecoilState(azureResourceState);
  let clearviewCustomers = useRecoilValue(clearviewCustomersState);
  const tenantConfig = useRecoilValue(tenantConfigState);
  const { logout } = useAuth();

  const selectCustomer = (customerId) => {
    setSelectedCustomer(clearviewCustomers.data.find((c) => c.id === customerId));
    setAzureResources(null);
    addCustomerIdToLocation(customerId);
  };

  // add customer ID to URL, e.g. /customer/:customerId/xxx
  const addCustomerIdToLocation = (customerId) => {
    const oldLocation = location.pathname;
    if (customerMatch) {
      const newLocation = oldLocation.replace(/customer(\/.+)?\/(.*)/, `customer/${customerId}/$2`);
      if (oldLocation !== newLocation) {
        navigate(newLocation);
      }
    }
  };

  useEffect(() => {
    if (!_.isNull(customerMatch)) {
      if (_.isNil(clearviewCustomers?.data)) {
        navigate("/customer");
        return;
      }

      if (selectedCustomer?.id !== customerMatch.params.customerId) {
        const customer = clearviewCustomers.data.find((c) => c.id === customerMatch.params.customerId);
        if (_.isUndefined(customer)) {
          navigate("/customer");
        } else {
          setSelectedCustomer(customer);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearviewCustomers, customerMatch, setSelectedCustomer]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 350,
      backgroundColor: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#a055f5" : state.isFocused ? "white" : "white",
      color: state.isSelected ? "white" : state.isFocused ? "#a100ff" : "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#96968c",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 800,
    }),
  };

  return (
    <CHeader className="header header-sticky mb-4">
      <CContainer fluid>
        <CHeaderNav className="flex-grow-1 ms-1">
          {clearviewCustomers && (
            <>
              <Select
                styles={customStyles}
                placeholder="Select customer..."
                value={
                  selectedCustomer
                    ? {
                        value: selectedCustomer.id,
                        label: `${selectedCustomer.companyProfile?.displayName} - ${selectedCustomer.sentia.customerId}`,
                      }
                    : null
                }
                options={
                  clearviewCustomers &&
                  Object.values(clearviewCustomers?.data)
                    .filter((v) => v?.status === "enabled" || v.status === undefined)
                    .sort((a, b) => (a.companyProfile?.displayName > b.companyProfile?.displayName ? 1 : -1))
                    .map((v) => {
                      return { value: v.id, label: `${v.companyProfile?.displayName} - ${v.sentia.customerId}` };
                    })
                }
                onChange={(e) => selectCustomer(e.value)}
              />

              <CButton
                className="mx-1"
                onClick={() => window.open(`https://portal.azure.com/@${selectedCustomer.id}`, "_blank", "noopener")}
              >
                Open Azure Portal
              </CButton>

              {query.get("debug") === "1" && (
                <CDropdown className="ml-2">
                  <CDropdownToggle caret>Selected Permissions</CDropdownToggle>
                  <CDropdownMenu>
                    {assignments &&
                      Object.values(assignments).map((v, k) => {
                        return <CDropdownItem key={k}>{v.displayName}</CDropdownItem>;
                      })}
                  </CDropdownMenu>
                </CDropdown>
              )}
            </>
          )}
        </CHeaderNav>

        <CHeaderNav className="ms-auto">
          <CNavItem className="mx-2">
            <Link className="nav-link" to="/status">
              Endpoint Status
            </Link>
          </CNavItem>

          <CNavItem className="mx-2">
            <Link className="nav-link" to="/settings">
              Settings
            </Link>
          </CNavItem>

          <CNavItem className="mx-2">
            <CNavLink href="#" onClick={() => logout()}>
              {`Logout (${tenantConfig?.name || "Unknown"})`}
              {tenantConfig?.config?.tenantIcon && <CIcon className="ms-2" name={tenantConfig?.config?.tenantIcon} />}
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default TheHeader;
