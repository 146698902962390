import { fetchFunctionHelper } from "../fetcher";

export function fetcherDevopsTest(devOpsPersonalAccessToken) {
  const endpoint = "https://app.vssps.visualstudio.com/_apis/profile/profiles/me?api-version=6.0";
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa(`:${devOpsPersonalAccessToken}`)}`,
  };

  return fetchFunctionHelper(endpoint, method, headers, null, [200, 200]);
}

export async function fetcherDevopsGet(url, devOpsPersonalAccessToken) {
  const result = await fetch(url, {
    headers: {
      Authorization: `Basic ${btoa(`:${devOpsPersonalAccessToken}`)}`,
      Accept: "application/json",
    },
  });

  return await result.json();
}

export async function fetcherDevopsPost(url, body, devOpsPersonalAccessToken) {
  const result = await fetch(url, {
    headers: {
      Authorization: `Basic ${btoa(`:${devOpsPersonalAccessToken}`)}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: body,
  });

  return await result.json();
}

export async function fetcherDevopsGetProjects(organization, token) {
  const endpoint = `https://dev.azure.com/${organization}/_apis/projects?api-version=5.1&$top=2000`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsListPipelineRuns(organization, project, buildId, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/pipelines/${buildId}/runs`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsGetRepositories(organization, project, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/git/repositories?api-version=5.1`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsGetBranches(organization, project, repository, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/git/repositories/${repository}/stats/branches`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsGetItems(organization, project, repository, params, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/git/repositories/${repository}/items${params}`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
    accept: "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsGetBuilds(organization, project, name, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/build/definitions?name=${name}`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsLaunchPipeline(organization, project, buildId, parameters, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/pipelines/${buildId}/runs?api-version=5.1`;
  const method = "POST";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers, parameters);

  return result;
}

export async function fetcherDevopsGetPipelineRun(organization, project, buildId, runId, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis/pipelines/${buildId}/runs/${runId}`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDevopsLaunchLegacyPipeline(organization, project, parameters, token) {
  const endpoint = `https://dev.azure.com/${organization}/${project}/_apis//build/builds?api-version=5.1`;
  const method = "POST";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers, parameters);

  return result;
}

export async function fetcherDevopsGetPackages(organization, project, feedId, token) {
  const endpoint = `https://feeds.dev.azure.com/${organization}/${project}/_apis/Packaging/Feeds/${feedId}/Packages?includeDescription=false&%24&includeDeleted=false`;
  const method = "GET";
  const headers = {
    Authorization: `Basic ${btoa(`:${token}`)}`,
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}
