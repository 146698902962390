import { atom, selector } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const currentUserPermissionsState = atom({
  key: "currentUserPermissionsState",
  default: null,
});

export const azureResourceState = atom({
  key: "azureResourceState",
  default: null,
  persistence_UNSTABLE: {
    type: "azureResourceState",
  },
});

export const azureResourceWithDetailsState = atom({
  key: "azureResourceWithDetailsState",
  default: null,
});

export const devopsAccessTokenState = atom({
  key: "devopsAccessToken",
  default: null,
});

export const devOpsPersonalAccessTokenValidationState = atom({
  key: "devOpsPersonalAccessTokenValidation",
  default: false,
  effects_UNSTABLE: [localStorageEffect("devOpsPersonalAccessTokenValidation")],
});

export const confluenceAccessTokenState = atom({
  key: "confluenceAccessToken",
  default: null,
});

export const inventoryAccessTokenState = atom({
  key: "inventoryAccessToken",
  default: null,
});

export const partnerCenterCustomersState = atom({
  key: "partnerCenterCustomers",
  default: null,
  effects_UNSTABLE: [localStorageEffect("partnerCenterCustomers")],
});

export const clearviewCustomersState = atom({
  key: "clearviewCustomersState",
  default: null,
  effects_UNSTABLE: [localStorageEffect("clearviewCustomersState")],
});

export const existingCustomerIdListState = selector({
  key: "existingCustomerIdListState",
  get: ({ get }) => {
    const list = get(clearviewCustomersState);
    return list?.items?.map((item) => {
      return item.id;
    });
  },
});

export const selectedCustomerState = atom({
  key: "selectedCustomer",
  default: null,
  effects_UNSTABLE: [localStorageEffect("selectedCustomer")],
});

export const selectedActivateCustomerState = atom({
  key: "selectedActivateCustomerState",
  default: null,
});

export const devOpsPersonalAccessTokenState = atom({
  key: "devOpsPersonalAccessTokenState",
  effects_UNSTABLE: [localStorageEffect("devOpsPersonalAccessTokenState")],
});

export const multiOrgPATState = atom({
  key: "multiOrgPATState",
  default: { azurelz: "", beoakteam: "" },
  effects_UNSTABLE: [localStorageEffect("multiOrgPATState")],
});

export const devOpsLocalHistoryState = atom({
  key: "devOpsLocalHistoryState",
  default: {},
  effects_UNSTABLE: [localStorageEffect("devOpsLocalHistoryStateV2")],
});

export const repositoryListState = atom({
  key: "repositoryListState",
  default: {},
});

export const softwareVersionState = atom({
  key: "softwareVersionState",
  default: "",
});

export const loaderStatusState = atom({
  key: "loaderStatusState",
  default: 0,
});

export const allAssignmentsState = atom({
  key: "allAssignmentsState",
  default: [],
});

export const selectedFlowState = atom({
  key: "selectedFlowState",
  default: {},
});

export const servicePrincipalState = atom({
  key: "servicePrincipalState",
  default: [],
  effects_UNSTABLE: [localStorageEffect("servicePrincipals")],
});
export const restoredPipelineParametersState = atom({
  key: "restoredPipelineParametersState",
  default: "",
});

export const tenantConfigState = atom({
  key: "tenantConfigState",
  default: {},
});
