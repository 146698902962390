// authProvider.js
// import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// import { Logger, LogLevel } from "msal";

// Msal Configurations
// const config = {
//   auth: {
//     authority: 'https://login.microsoftonline.com/common/',
//     clientId: process.env.REACT_APP_AZURE_AD_APP_CLIENTID,
//     // redirectUri: "",
//     validateAuthority: true,
//   },
//   system: {
//     logger: new Logger(
//       (logLevel, message, containsPii) => {
//         console.log("[MSAL]", message);
//       },
//       {
//         level: LogLevel.Error,
//         piiLoggingEnabled: false
//       }
//     )
//   },
//   cache: {
//     cacheLocation: "sessionStorage",
//     storeAuthStateInCookie: true
//   }
// };

export const msalConfig = {
  auth: {
    clientId: "85dd548f-93c6-4589-8742-ad90e491d4d8",
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Authentication Parameters
// const authenticationParameters = {
//     scopes: ["https://management.azure.com/user_impersonation"]
// }

// const authenticationParametersKV = {
//   scopes: ["https://vault.azure.net/.default"]
// }

// const authenticationParametersAAD = {
//   scopes: ["https://graph.microsoft.com/.default"]
// }

// // Options
// const options = {
//   loginType: LoginType.Redirect,
//   tokenRefreshUri: window.location.origin
// }

//  export const authProvider = {}
//  export const authProviderKV = {}
//  export const authProviderAAD = {}
