import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { CContainer } from "@coreui/react";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  return (
    <main className="body flex-grow-1 px-3">
      <CContainer fluid className="container-xxl container-lg">
        <Suspense fallback={loading}>
          <Outlet />
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);
