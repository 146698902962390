import React, { useEffect } from "react";
import { TheContent, TheSidebar, TheFooter, TheHeader } from "./index";
import LoadingBar from "react-top-loading-bar";

import { useSetRecoilState, useRecoilState } from "recoil";
import {
  currentUserPermissionsState,
  softwareVersionState,
  loaderStatusState,
  allAssignmentsState,
  tenantConfigState,
} from "../helpers/recoil";
import { fetcherAADGroupMembership, fetcherApplicationPermissions, fetcherTenantConfig } from "../helpers/fetchers";

import { ToastContainer } from "react-toastify";
import { useAuth } from "../helpers/authHooks";

const TheLayout = (props) => {
  // Startup Checks
  const setAllAssignments = useSetRecoilState(allAssignmentsState);
  const setCurrentUserPermissions = useSetRecoilState(currentUserPermissionsState);
  const setSoftwareVersion = useSetRecoilState(softwareVersionState);
  const [progress, setProgress] = useRecoilState(loaderStatusState);
  const setTenantConfig = useSetRecoilState(tenantConfigState);
  const { getAccessTokenMSGraph } = useAuth();

  useEffect(() => {
    fetcherTenantConfig().then(async (data) => {
      if (data.isValid) {
        setTenantConfig(data.result.data);
      }
    });

    let getSoftwareVersion = async () => {
      fetch("/version.json").then(async (data) => setSoftwareVersion((await data.json())?.build || "Unknown"));
    };
    let groupMemberships = async () => {
      const azureAADAccessToken = await getAccessTokenMSGraph();
      fetcherAADGroupMembership(azureAADAccessToken).then(async (data) => {
        try {
          const assignments = (await fetcherApplicationPermissions()).result.data;
          setAllAssignments([...new Set(assignments)]);

          const filteredAssignments = assignments
            ?.filter((v) => data.result.data.value.includes(v.id))
            .map((v) => {
              return v.assignments;
            });
          setCurrentUserPermissions(...new Set(filteredAssignments));
        } catch {
          setCurrentUserPermissions(["cloudbilling:read"]);
        }
      });
    };
    getSoftwareVersion();
    groupMemberships();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="wrapper c-wrapper d-flex flex-column min-vh-100 bg-light">
        <TheHeader {...props} />
        <div className="c-body">
          <ToastContainer autoClose={3000} icon={false} theme={"colored"} />
          <LoadingBar color="#7500c0" progress={progress} onLoaderFinished={() => setProgress(0)} />
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayout;
