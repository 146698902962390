import { getConfig } from "./config";

export function formatter(slug) {
  return getConfig("backend") + slug;
}

export function formatterIV(slug) {
  return process.env.REACT_APP_IV_FUNCTION_URL_HOST + slug;
}

export function formatterLocal(slug) {
  return "http://localhost:7071/api" + slug;
}
