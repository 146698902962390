import React from "react";
import { CModal, CModalHeader, CModalBody, CModalFooter, CButton } from "@coreui/react";

import { DiffEditor } from "@monaco-editor/react";

const DiffModal = (props) => {
  return (
    <CModal visible={props.visible} onClose={() => props.onClose()} fullscreen>
      <CModalHeader closeButton>Last committed config vs deployed config</CModalHeader>
      <CModalBody>
        <p>Bicep config</p>
        <DiffEditor
          height="80vh"
          defaultLanguage="json"
          original={props.latestConfig || ""}
          modified={props.currentConfig || ""}
          options={{
            readOnly: true,
            scrollBeyondLastLine: true,
            scrollbar: { alwaysConsumeMouseWheel: true },
            renderSideBySide: false,
          }}
        />
      </CModalBody>
      <CModalBody>
        <p>Global Params</p>
        <DiffEditor
          height="80vh"
          defaultLanguage="json"
          original={props.getLatestGlobalParams || ""}
          modified={props.currentParamConfig || ""}
          options={{
            readOnly: true,
            scrollBeyondLastLine: true,
            scrollbar: { alwaysConsumeMouseWheel: true },
            renderSideBySide: false,
          }}
        />
      </CModalBody>
      <CModalBody>
        <p>Global Tags</p>
        <DiffEditor
          height="80vh"
          defaultLanguage="json"
          original={props.getLatestGlobalTags || ""}
          modified={props.currentGlobalTagsConfig || ""}
          options={{
            readOnly: true,
            scrollBeyondLastLine: true,
            scrollbar: { alwaysConsumeMouseWheel: true },
            renderSideBySide: false,
          }}
        />
      </CModalBody>
      <CModalFooter>
        <CButton onClick={() => props.onClose()}>Close</CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DiffModal;
