import React from "react";
import { CModal, CModalHeader, CModalBody, CCol, CLink, CRow, CCard, CCardBody } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const PipelineHistory = (props) => {
  return (
    <CModal size="xl" visible={props.visible} onClose={() => props.onClose()} scrollable={true}>
      <CModalHeader closeButton>Deployment History</CModalHeader>
      <CModalBody>
        <CCard className="mb-2">
          <CCardBody>
            <CRow>
              <CCol xs={2}>
                <strong>Timestamp</strong>
              </CCol>
              <CCol xs={2}>
                <strong>Environment</strong>
              </CCol>
              <CCol xs={3} className="text-truncate">
                <strong>Solution</strong>
              </CCol>
              <CCol xs={1}>
                <strong>Version</strong>
              </CCol>
              <CCol xs={1}>
                <strong>Status</strong>
              </CCol>
              <CCol xs={2}>
                <strong>Triggered by</strong>
              </CCol>
              <CCol xs={1}>
                <strong>Logs</strong>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        {props.pipelineData.map((pipelineRun) => {
          return (
            <CCard className="mb-2" key={pipelineRun.id}>
              <CCardBody>
                <CRow>
                  <CCol xs={2}>{new Date(pipelineRun.timestamp).toLocaleString()}</CCol>
                  <CCol xs={2}>{pipelineRun.environment}</CCol>
                  <CCol xs={3} className="text-truncate">
                    {pipelineRun.solutionName}
                  </CCol>
                  <CCol xs={1}>{pipelineRun.runtimeSolutionVersion}</CCol>
                  <CCol xs={1}>
                    {pipelineRun.status === "Succeeded" ? (
                      <CIcon name="cil-check-circle" style={{ color: "#2eb85c" }} />
                    ) : (
                      <CIcon name="cil-x-circle" style={{ color: "red" }} />
                    )}
                  </CCol>
                  <CCol xs={2}>{pipelineRun.triggeredBy.split("@")[0]}</CCol>
                  <CCol xs={1}>
                    <CLink
                      target="_blank"
                      href={`https://dev.azure.com/${pipelineRun.organization || "AzureLZ"}/${
                        pipelineRun.projectId
                      }/_build/results?buildId=${pipelineRun.buildId}&view=logs`}
                    >
                      <CIcon name="cil-external-link" />
                    </CLink>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          );
        })}
      </CModalBody>
    </CModal>
  );
};

export default PipelineHistory;
